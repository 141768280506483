body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.image {
  opacity: 1;
  height: 200;
  width: 200;
  object-fit: 'cover';
  border: 'thin solid grey';
  border-radius: 25;
  margin-right: 15;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: relative;
  left: 47%;
  bottom: 40%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.text {
  color:#474747;
  font-weight: bold;
  font-size: 16px;
  padding: 16px 32px;
}

.imageArrayRoot {
  flex: 1; 
  display: flex; 
  flex-direction: row; 
  flex-wrap: wrap;
}
